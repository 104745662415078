import React, { useState, useEffect } from "react"
import classNames from "classnames"
import axios from "axios"

import styles from "./utils/layout.module.scss"
import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"

const Notification = ({ isOTPpage = false }) => {
  const [notificationHidden, setNotificationHidden] = useState(false)
  const hasSeenNotificationMessage = hasSeenNotification()
  const [notification, setNotification] = useState(null)

  useEffect(() => {
    const getBanner = async () => {
      try {
        const params = {
          website: process.env.GATSBY_GITHUB_REPO,
          env: process.env.GATSBY_ENV,
        }

        const response = await axios.get(
          process.env.GATSBY_AIRTABLE_BANNER_ENDPOINT,
          { params }
        )

        let actualBannerContent = response?.data?.content || null
        const otpMessage =
          "If you have not received an OTP within 5 minutes, please reach out to us at pulsecare@medgrocer.com. Thank you."

        if (actualBannerContent !== notification) {
          // console.log(isOTPpage)
          let updatedBanner = actualBannerContent || ""
          if (isOTPpage) {
            updatedBanner += `\n ${otpMessage}`
          }
          setNotification(updatedBanner)
        }
      } catch (error) {
        console.error("Error fetching banner:", error)
      }
    }

    getBanner()
  }, [isOTPpage])

  if (notification && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-warning",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          aria-label="Delete Button"
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <div className="has-text-centered is-size-6">
          <p
            dangerouslySetInnerHTML={
              notification ? { __html: notification } : null
            }
          />
        </div>
        <div></div>
      </div>
    )

  return null
}

export default Notification
